//Jquery
window.$ = window.jQuery = require('jquery');

//**************************************************************************************************************
// Password field show/hide
$('.toggle-password').click(function () {
	$(this).toggleClass('fa-eye fa-eye-slash');

	let input = $($(this).attr('toggle'));

	if (input.attr('type') === 'password') {
		input.attr('type', 'text');
	} else {
		input.attr('type', 'password');
	}
});

// t.prototype.initShowHidePassword = function() {
// 	a("[data-password]").on("click", function() {
// 		"false" == a(this).attr("data-password") ? (a(this).siblings("input").attr("type", "text"),
// 			a(this).attr("data-password", "true"),
// 			a(this).addClass("show-password")) : (a(this).siblings("input").attr("type", "password"),
// 			a(this).attr("data-password", "false"),
// 			a(this).removeClass("show-password"))
// 	}
// }
